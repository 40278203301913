export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "KEYWORD_LIST_GET_SUCCESS":
    case "KEYWORD_LIST_GET_ERROR":
      return {
        ...state,
        KeywordListResponse: action.updatePayload,
      };

    case "KEYWORD_ADD_SUCCESS":
    case "KEYWORD_ADD_ERROR":
      return {
        ...state,
        AddKeywordResponse: action.updatePayload,
      };

    case "FAKE_ACTION_ADMIN_KEYWORD":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
