import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { Button, Form, Input, message } from "antd";
import { useSelector } from "react-redux";
import AuthWrapper from "../style";
import Wrapper from "../create-account/style";
import { useHistory } from "react-router-dom";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 16,
    },
    sm: {
      span: 16,
    },
  },
};

export default function (props) {
  const { forgotPasswordAction, fakeActionAuth } = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const ForgotPasswordRes = useSelector(
    (state) => state.Auth.ForgotPasswordResponse || {}
  );

  useEffect(() => {
    if (ForgotPasswordRes?.status === true) {
      setLoading(false);
      setRedirect(true);
      message.success(
        ForgotPasswordRes?.message ||
          "Link to reset your password has been sent to your email."
      );
      fakeActionAuth("ForgotPasswordResponse");
    } else if (ForgotPasswordRes?.status === false) {
      setLoading(false);
      message?.destroy();
      if (ForgotPasswordRes?.error || ForgotPasswordRes?.error_data) {
        message.warning(
          Object.values(
            ForgotPasswordRes?.error || ForgotPasswordRes?.error_data
          )?.[0]?.[0] ||
            ForgotPasswordRes?.message ||
            "Something Went Wrong."
        );
      } else {
        message.warning("Something Went Wrong.");
      }
      fakeActionAuth("ForgotPasswordResponse");
    }
  }, [ForgotPasswordRes]);

  const onCreate = (values) => {
    setLoading(true);
    forgotPasswordAction(values);
  };

  if (redirect) {
    return <Redirect to="/login" />;
  }

  document.title = "Forgot Password";
  if (localStorage.getItem("userType")) {
    return (
      <Redirect
        to={
          localStorage.getItem("userType") == 1
            ? `/manage-seller-users`
            : `/dashboard`
        }
      />
    );
  }

  return (
    <Wrapper style={{ height: "100%", overflow: "auto" }}>
      <div className="row" style={{ height: "100%", marginRight: "0px" }}>
        <div
          id="div1"
          style={{ background: "#eff3fe", overflow: "auto" }}
          className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6  flex-column d-flex"
        >
          <div className="d-flex flex-row-fluid flex-column text-center p-10">
            <span className="cursor-pointer py-9 mb-5 mt-20 d-flex justify-content-center">
              <img
                loading="lazy"
                alt="Logo"
                src="/logos/full-logo.png"
                className="h-70px  shimmer"
                onError={(e) => {
                  e.target.src =
                    "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
                }}
              />
            </span>
            <h1
              className="fs-2qx pb-5 pb-md-4 fw-normal"
              style={{ color: "#494951" }}
            >
              Welcome to <b className="fw-boldest">Amzster</b>
            </h1>
            <p className="fw-normal fs-3" style={{ color: "#494951" }}>
              An Intelligent tool for Amazon Product
            </p>
          </div>
          <div className="authFadeInTop d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px">
            <img
              src="/assets/back-add-user-01.png"
              alt="login-image.png"
              className="mx-auto"
              loading="lazy"
              style={{ width: "50%" }}
              onError={(e) => {
                e.target.src =
                  "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
              }}
            />
          </div>
        </div>
        <div
          id="div2"
          style={{ background: "#fff", height: "auto", overflow: "auto" }}
          className="authFadeInBottom d-flex flex-column col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
        >
          <div className="d-flex flex-column flex-lg-row-fluid py-10">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div className="w-lg-500px  mx-auto">
                <div className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework">
                  {/*begin::Heading*/}
                  <div className="mb-10 text-center">
                    {/*begin::Title*/}
                    <h1
                      className="text-dark fw-bold mb-3"
                      style={{ color: "#494951" }}
                    >
                      Forgot <b className="fw-boldest">Password ?</b>
                    </h1>
                    {/*end::Title*/}
                    {/*begin::Link*/}
                    <div className="text-gray-400 fw-bold fs-4">
                      Enter your email to reset your password.
                    </div>
                    {/*end::Link*/}
                  </div>
                  {/*end::Heading*/}
                  {/*begin::Input group=*/}
                  <Form
                    {...formItemLayout}
                    layout="vertical"
                    form={form}
                    disabled={loading}
                    name="reset-password"
                    // style={{ width: checkWidth() <= 576 ? "75%" : "100%" }}
                    style={{ width: "100%" }}
                  >
                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "E-mail is required",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input autoFocus size="large" autocomplete="off" />
                    </Form.Item>
                  </Form>
                  {/*end::Input group=*/}
                  {/*begin::Action*/}
                  <div className="text-center">
                    <Button
                      type="primary"
                      size="large"
                      id="kt_password_reset_submit"
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            onCreate(values);
                          })
                          .catch((info) => {});
                      }}
                    >
                      {loading ? (
                        <span>
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      ) : (
                        <span className="indicator-label">Submit</span>
                      )}
                    </Button>
                    <Button
                      target="/login"
                      type="ghost"
                      size="large"
                      onClick={() => history.push("/login")}
                      className=" fw-bolder ms-3"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div />
                </div>
              </div>
            </div>
            <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
              {/*begin::Links*/}
              <div className="d-flex flex-center fw-bold fs-6">
                <a href className="text-muted text-hover-primary px-2">
                  About
                </a>
                <a href className="text-muted text-hover-primary px-2">
                  Support
                </a>
                <a href className="text-muted text-hover-primary px-2">
                  Purchase
                </a>
              </div>
              {/*end::Links*/}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
