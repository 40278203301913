import styled from "styled-components";

const Wrapper = styled.div`
  background: rgba(110, 175, 38, 0.12);
  height: 100%;
  overflow: auto;
  .authFadeInBottom {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  .bgColor {
    background: rgb(9 91 155 / 60%);
    mix-blend-mode: hue;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
  }
  .autoCard {
    z-index: 9;
  }
`;

export default Wrapper;
