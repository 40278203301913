import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ResetPassword from "../../../modules/pages/auth/forgot-password/reset-password";
import {
  resetPasswordAction,
  fakeActionAuth,
} from "../../../redux/modules/auth/index.action";

const mapStateToProps = (state) => ({
  ResetPasswordResponse: state.Auth.ResetPasswordResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ resetPasswordAction, fakeActionAuth }, dispatch);

const ResetPasswordASINWiser = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);

export default ResetPasswordASINWiser;
