import Auth from "../../modules/auth/index.reducer";
import User from "../../modules/admin/user/index.reducer";
import AppConfiguration from "../../modules/admin/setting/app-configuration/index.reducer";
import AdminScheduler from "../../modules/admin/setting/admin-scheduler/index.reducer";
import Price from "../../modules/pricings/pricings.reducer";
import AppLogs from "../../modules/admin/application-logs/application-logs.reducer";
import UserAppLogs from "../../modules/application-logs/index.reducer";
import ProductMaster from "../../modules/product_master/index.reducer";
import UserMarketplace from "../../modules/setting/marketplace-credentials/index.reducer";
import ProfileR from "../../modules/setting/profile/profile.reducer";
import CriteriaAnalysis from "../../modules/admin/criteria-analysis/index.reducer";
import Keywords from "../../modules/admin/keywords/index.reducer";

const reducers = {
  Auth,
  User,
  AppLogs,
  AppConfiguration,
  AdminScheduler,
  Price,
  ProductMaster,
  UserMarketplace,
  ProfileR,
  CriteriaAnalysis,
  UserAppLogs,
  Keywords,
};

export default reducers;
