import styled from "styled-components";

const Wrapper = styled.div`
  background-color: #f9fafb;
  height: 100vh;
  .hideScroll::-webkit-scrollbar-track {
    ${'' /* transition: all 0.8s ease 0s;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 6px inset !important; */}
  }
  // .ant-input-affix-wrapper {
  //   background-color: #e8f0fe !important;
  //   border-color: #d9d9d9 !important;
  // }
  #register {
    margin: auto;
    width: fit-content;
  }

  // #register_email, #register_first-name, #register_company-name, #register_phone-number {
  //   background-color: #e8f0fe !important;
  //   border-color: #d9d9d9 !important;
  //   &:hover {
  //     border-color: #7e78ef96 !important;
  //   }
  // }
`;

export default Wrapper;