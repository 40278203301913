export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "USER_CREDENTIALS_LIST_SUCCESS":
    case "USER_CREDENTIALS_LIST_ERROR":
      return {
        ...state,
        UserCredentialsListResponse: action.updatePayload,
      };
    case "GET_MARKETPLACE_LIST_SUCCESS":
    case "GET_MARKETPLACE_LIST_ERROR":
      return {
        ...state,
        GetMarketplaceListResponse: action.updatePayload,
      };
    case "USER_GENERATE_REFRESH_TOKEN_SUCCESS":
    case "USER_GENERATE_REFRESH_TOKEN_ERROR":
      return {
        ...state,
        UserGenerateRefreshTokenResponse: action.updatePayload,
      };
    case "USER_GENERATE_REFRESH_AD_TOKEN_SUCCESS":
    case "USER_GENERATE_REFRESH_AD_TOKEN_ERROR":
      return {
        ...state,
        UserGenerateRefreshAdTokenResponse: action.updatePayload,
      };
    case "USER_CREDENTIAL_SAVE_AD_PROFILE_SUCCESS":
    case "USER_CREDENTIAL_SAVE_AD_PROFILE_ERROR":
      return {
        ...state,
        UserCredentialSaveAdProfileResponse: action.updatePayload,
      };
    case "FAKE_ACTION_MARKETPLACE_CREDENTIALS_LOGS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
