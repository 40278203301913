import { createGlobalStyle } from "styled-components";
import { themeColor } from "./config";

const AppStyleWrapper = createGlobalStyle`
body {
    background-color: #f3f6f9;
    font-family: 'Inter';
}
#webpack-dev-server-client-overlay {
  display: none;
}
.ant-btn-primary {
  color: #fff;
  box-shadow: none !important;
  background-color: ${() => themeColor()};
    transition-duration: 300ms;
  &:hover {
    background-color: ${() => themeColor("", "hover")} !important;
    box-shadow: 0px 0px 10px ${() => themeColor()} !important;
  }
}
.ant-avatar {
  min-width: 40px !important;
  border-radius: 30px !important;
}
.ant-btn-ghost {
  color: #095b9b;
  background: rgb(98 98 98 / 13%);
  font-weight: 900 !important;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  &:hover {
    color: #FFF;
    background: #095b9b;
    box-shadow: rgb(98 98 98 / 52%) 0px 0px 10px !important;
  }
}
.link-primary {
  color: ${() => themeColor()};
  &:hover {
    color: ${() => themeColor("", "hover")};
  }
}

*::-webkit-scrollbar {
  width: 1rem !important;
  height: 1rem !important;
}

*::-webkit-scrollbar-thumb {
  height: 1rem !important;
  border-radius: 0.5rem !important;
  border: 4px solid transparent !important;
  background-clip: content-box !important;
  background-color: ${() => themeColor()} !important;

  &:hover {
      background-color: #5757a6 !important;
  }
}
  .ant-divider-horizontal.ant-divider-with-text::after,
.ant-divider-horizontal.ant-divider-with-text::before {
    background: rgb(194, 194, 194) !important;
}
*::-webkit-scrollbar-track {
  background-color: #FFF !important;
}






.btn-success, .nav-link.active {
  background-color: ${() => themeColor()} !important;
  &:hover {
  background-color: ${() => themeColor("", "hover")} !important;
  }
}
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(
      to right bottom,
      rgb(255 255 255),
      #ffffff
    ) !important;
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .ant-tooltip-inner {
    background-color: rgb(255 255 255) !important;
    font-weight: 500;
    color: #000 !important;
  }
  .ant-tooltip-arrow:before {
    background: white !important;
  }
  .ant-tooltip-inner {
    color: #000 !important;
    a {
      color: #000 !important;
    }
  }

    .shimmer {
        -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
          100%;
        background-repeat: no-repeat;
        animation: shimmer 2.5s infinite;
    }
    @keyframes shimmer {
        100% {
          -webkit-mask-position: left;
        }
    }
  ::selection {
    color: ${() => themeColor("hover")};
    background: ${() => themeColor("", "light")};
  }
  body > #root {
    height: 100%;
  }
  body > #root > .globalHeight {
    height: 100%;
  }
  .antd-success {
    color: #fff !important;
    background-color: ${() => themeColor()} !important;
  }
  .authFadeInBottom {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: authFadeInBottom;
  }

  @keyframes authFadeInBottom {
    from {
      opacity: 0;
      transform: translateY(-25%);
    }
    to {
      opacity: 1;
    }
  }



  .fade-in {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fadeInBottom {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInBottom;
  }

  @keyframes fadeInBottom {
    from {
      opacity: 0;
      width: 0px !important;
      ${"" /* transform: translateY(-100%); */}
    }
    to {
      opacity: 1;
    }
  }
  .fadeInLeft {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInLeft;
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
    }
  }
  .fadeInRight {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInRight;
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(30px, 30px, 8px);
    }
    to {
      opacity: 1;
    }
  }
  .fadeInHideRight {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInHideRight;
  }

  @keyframes fadeInHideRight {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      height: 0px;
    }
  }

  @media only screen and (min-width: 550px) {
    .smallScreenPageHeader {
      display: none !important;
    }
  }
  .ml-auto {
    margin-left: auto;
  }
  #kt_wrapper {
    overflow: auto;
    height: 100vh;
  }


  .Edit-Icon-Antd {
    cursor: pointer;
    margin: 0px 10px;
    svg {
      transition: 0.6s;
      font-size: 20px;
      path:first-child {
        fill: white;
      }
      path {
        fill: grey;
      }
    }
    &:hover {
      svg {
        transform: scale(1.2);
        path {
          fill: grey;
        }
      }
    }
  }
  .info-product-research {
    padding: 0 !important;
    font-size: 11px !important;
  }
.Eye-Icon-Antd, .Copy-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  width: 23px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path::nth-child(3), path::nth-child(4) {
      fill: #0062ff;
    }
    path::nth-child(2) {
      fill: #91caff;
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path {
        fill: #0062ff;
      }
      path::nth-child(3), path::nth-child(4) {
        fill: #FFF !important;
      }
    }
  }
}
.Download-Icon-Antd {
  cursor: pointer;
  svg {
    transition: 0.6s;
    font-size: 20px;
    color: #00a1ff;
  }
  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
}
.File-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path:first-child {
      fill: white;
    }
    path {
      fill: #7472ee;
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path:first-child {
        fill: #7472ee56;
      }
      path {
        fill: #7472ee;
      }
    }
  }
}
.Delete-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path:first-child {
      fill: white;
    }
    path {
      fill: rgb(255 114 114);
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path:first-child {
        fill: rgb(255 114 114);
      }
      path {
        fill: rgb(255 114 114);
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.align-item-center {
  align-items: center;
}
.ant-table-layout-fixed table {
    table-layout: auto;
}
.ant-upload-list-item-error {
  border-color: ${() => themeColor("", "light")} !important;
}
.ant-btn-lg {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}
input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
}

#nprogress .bar {
    background: ${() => themeColor()};
    position: fixed;
    z-index: 1031999999999999999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
}


/* Fancy blur effect */

#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${() => themeColor()}, 0 0 5px ${() => themeColor()};
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}
#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: ${() => themeColor()};
    border-left-color: ${() => themeColor()};
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}
.svg-icon.svg-icon-success svg [fill]:not(.permanent):not(g) {
    transition: fill 0.3s ease;
    fill: ${() => themeColor()};
}
.ant-table-wrapper .ant-table, .ant-table-wrapper .ant-table-thead >tr>th {
  background: #f5f8fa00;
}
.ant-table-wrapper .ant-table-thead >tr>th {
  color: #36363e;
}
.ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td {
  background: #f5f8fa !important;
  // border-bottom: 1px solid #bdaec2 !important;
}
#topbar_menus-home-popup {
  .ant-menu-item-only-child {
    height: 80px !important;
  }
  .menu-link  {
    padding: 0.85rem !important;
  }
  .ant-menu-item-selected {
    background-color: #bdaec228 !important;
  }
}
.fadeInRight {
  animation-duration: 0.7s;
  animation-fill-mode: both;
  animation-name: fadeInRight;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(30px, 30px, 8px);
  }
  to {
    opacity: 1;
  }
}
.note {
  height: 181px;
  overflow: auto;
  border: 1px solid #88CDFF47;
  border-radius: 2px;
  margin: 10px 0px;
  width: 100%;
  textarea:focus {
    background-color: #e2e5ef !important;
  }
  .TextEditor {
    height: 181px;
    min-height: 181px;
    width: 100%;
    resize: none;
    color: #000;
    background-color: #e2e5ef;
    border: none;
    background-repeat: repeat-y,repeat;
    background-size: 100% 40px;
    font-size: 12px;
    letter-spacing: 0.35px;
    font-weight: 300;
    line-height: 40px;
    padding: 10px 15px 19px;
    background-image: url(/assets/Line-01.png);
  
  }
}

.hide-sidebar {
    transition: padding-left 0.3s ease;
    padding-left: 0px;
}
.hide-sidebar_ {
    transition: padding-left 0.3s ease;
    padding-left: 0px;
    width: 100px;
}




.sidebar {
  background-color: rgb(75, 0, 130);
  color: #fff;
  width: 200px;
  height: 100vh;
  padding: 20px;
  overflow: hidden; /* Hide the scrollbar by default */
}

.sidebar:hover {
  overflow-y: scroll; /* Show the scrollbar on hover */
}


.ant-dropdown .menu {
    border-radius: 0.475rem;
    background-color: #ffffff !important;
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15) !important;
}

.expand-sidebar {
  // min-width: fit-content;
}
.wrapper {
  padding-top: 0;
  padding-left: 0;
}
.hide-sidebar {
  padding-left: 0px;
}

.active-text {
  
  color: #095b9b !important;
}
.user-panel {
  padding-left: 0 !important;
}
.hover-scroll-overlay-y {
  &:hover {
    // padding-left: 12px;
    // margin: auto;
  }
}
.ant-btn-default {
  background: linear-gradient(142deg, ${() => themeColor()}, ${() =>
  themeColor("", "light")}) !important;
  font-weight: 900 !important;
    color: rgb(255 255 255) !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px !important;
  transition: 0.7s !important;
  border: 1px solid #FFF !important;
  &:hover {
  border: 1px solid ${() => themeColor()} !important;
      // border: none;
  }
}
.ant-btn-dashed {
  
  border-color: #095b9b !important;
  
}
.show_normal, .expand-sidebar {
  animation: slide-out 1000ms !important;
}

@keyframes slide-out {
  from {
    max-width: 100px;
  }
  to {
    max-width: 365px;
  }
}
.hide-sidebar_ {
  width: 100px;
  animation: slide-in 1000ms !important;
}
@keyframes slide-in {
  from {
    width: 365px;
  }
  to {
    width: 100px;
  }
}

.ant-tag-borderless {
  border: none !important;
}
  .ant-card-body {
        height: calc(30vh - 0px);
    overflow: auto;
  }

  .btn.btn-primary:hover:not(.btn-active) {
      background-color: #06406b !important;
  }
`;

export default AppStyleWrapper;
