import Icons from "../components/icons";
import { Link } from "react-router-dom";
export const AppName = "Amzster";
export const EndPoint = "https://listingapi.ergodeinc.com/";
export const colorHex = "#095b9b";
export const API_URL = `${EndPoint}api/v1/`;
export const imageURL = `${EndPoint}uploads/`;
export const pageDropdown = [10, 25, 50, 100];
export const DefaultPerPage = 10;
export const priceCommonSign = "$";
export const planToken = "";
export const priceSign = {
  "United States(Amazon.com)": "$",
  "US(Amazon.com)": "$",
  "UK(Amazon.co.uk)": "£",
  "United Kingdom": "£",
  "Germany(Amazon.de)": "€",
  Germany: "€",
  "France(Amazon.fr)": "€",
  France: "€",
  "Canada(Amazon.ca)": "$",
  "Italy(Amazon.it)": "€",
  Italy: "€",
  "Spain(Amazon.es)": "€",
  Spain: "€",
  "India(Amazon.in)": "₹",
  "Mexico(Amazon.com.mx)": "$",
};
export const numberformat = (value) => {
  if (value === "-") return 0;
  return parseFloat(value || 0).toLocaleString("en-US", {
    maximumFractionDigits: 2,
  });
};
function hexToRgbA(hex, op) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      (op || 1) +
      ")"
    );
  }
  throw new Error("Bad Hex");
}
function changeColor(color, amount) {
  const clamp = (val) => Math.min(Math.max(val, 0), 0xff);
  const fill = (str) => ("00" + str).slice(-2);

  const num = parseInt(color.substr(1), 16);
  const red = clamp((num >> 16) + amount);
  const green = clamp(((num >> 8) & 0x00ff) + amount);
  const blue = clamp((num & 0x0000ff) + amount);
  return (
    "#" +
    fill(red.toString(16)) +
    fill(green.toString(16)) +
    fill(blue.toString(16))
  );
}
export const themeColor = (op, type) => {
  if (type === "hover") {
    return changeColor(colorHex, -100);
  } else if (type === "light") {
    return changeColor(colorHex, 100);
  } else if (type) {
    return hexToRgbA(colorHex, op);
  }
  return colorHex;
};
export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};
export const clearLocalData = () => {
  localStorage.clear();
  window.location.assign("/login");
  window.location.reload();
};
export const handleStorageChange = (e) => {
  e.stopPropagation();
  if (e.url === window.location.href) {
    if (!e.key) {
      clearLocalData();
    } else {
      localStorage.setItem(e.key, e.oldValue);
    }
  }
  if (
    !localStorage.getItem("token") ||
    !localStorage.getItem("user") ||
    !localStorage.getItem("userType") ||
    !localStorage.getItem("menusList")
  ) {
    clearLocalData();
  }
};
export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date * 1000) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return Math.floor(seconds) + " seconds ago";
};
export const testArr = [
  "dashboard",
  "rules",
  "action-logs",
  "advertising-reports",
  "setting",
  "application-logs",
];
export const adminMenus = ["dashboard", "manage-seller-users"];
const GetIcons = (icon) => {
  return <Icons type={icon} />;
};
const bullet = (
  <span className="menu-bullet">
    <span className="bullet bullet-dot" />
  </span>
);
const Modules = [
  {
    title: "Manage Seller Users", // sidebar title
    name: "manage-seller-users", // sidebar key
    path: ["/manage-seller-users"], // sidebar pathName
    index: [
      {
        admin: 2, // is use admin side then add series no.
        common: -1, // is use user side then add series no.
      },
    ],
    rule: ["admin"], // admin and user side
    icon: GetIcons("users"), // icon
  },
  {
    title: "Dashboard",
    name: "dashboard",
    path: ["/", "/dashboard"],
    index: [
      {
        admin: 1,
        common: 1,
      },
    ],
    rule: ["admin", "common"],
    icon: GetIcons("DashboardNew"),
  },
  {
    title: "Home",
    name: "home",
    parentPath: "/home",
    path: [],
    index: [
      {
        admin: -1,
        common: 1,
      },
    ],
    rule: ["common"],
    icon: GetIcons("competitor-research"),
    children: [
      {
        title: "Dashboard",
        name: "dashboard",
        path: ["/dashboard"],
        index: [],
        parent: "home",
        icon: GetIcons("DashboardNew"),
      },
      {
        title: "Product Master",
        name: "product-master",
        path: ["/product-master"],
        index: [],
        parent: "home",
        icon: GetIcons("ProductNew"),
      },
    ],
  },
  {
    title: "Sp Api Reports",
    name: "sp-api-reports",
    parentPath: "/sp-api-reports",
    path: [],
    index: [
      {
        admin: -1,
        common: 2,
      },
    ],
    rule: ["common"],
    icon: GetIcons("competitor-research"),
    children: [
      {
        title: "Dashboard",
        name: "dashboard",
        path: ["/dashboard"],
        index: [],
        parent: "sp-api-reports",
        icon: GetIcons("DashboardNew"),
      },
    ],
  },

  {
    title: "Manage Subscription",
    name: "manage-subscription",
    path: ["/manage-subscription"],
    index: [
      {
        admin: 3,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("pricing"),
  },
  {
    title: "Setting",
    name: "setting",
    parentPath: "/setting",
    path: [],
    index: [
      {
        admin: 4,
        common: -1,
      },
    ],
    rule: ["admin"],
    icon: GetIcons("setting"),
    children: [
      {
        title: "App Configuration",
        name: "app-configuration",
        path: ["/app-configuration"],
        index: [],
        parent: "setting",
        icon: bullet,
      },
      {
        title: "Admin Scheduler",
        name: "admin-scheduler",
        path: ["/admin-scheduler"],
        index: [],
        parent: "setting",
        icon: bullet,
      },
    ],
  },
  {
    title: "Setting",
    name: "setting",
    parentPath: "/setting",
    path: [],
    index: [
      {
        admin: -1,
        common: 3,
      },
    ],
    rule: ["common"],
    icon: GetIcons("setting_"),
    children: [
      {
        title: "Profile",
        name: "profile",
        path: ["/profile"],
        index: [],
        parent: "setting",
        icon: GetIcons("ProfileNew"),
      },
      {
        title: "Marketplace Credentials",
        name: "marketplace-credentials",
        path: ["/marketplace-credentials"],
        index: [],
        parent: "setting",
        icon: GetIcons("MarketplaceNew"),
      },
    ],
  },
  {
    title: "Application Logs",
    name: "application-logs",
    parentPath: "/application-logs",
    path: [],
    index: [
      {
        admin: -1,
        common: 4,
      },
    ],
    rule: ["common"],
    icon: GetIcons("application-logs"),
    children: [
      {
        title: "Central Log",
        name: "central-log",
        path: ["/central-log"],
        index: [],
        parent: "application-logs",
        icon: GetIcons("LogNew"),
      },
      {
        title: "System Error Log",
        name: "system-error-log",
        path: ["/system-error-log"],
        index: [],
        parent: "application-logs",
        icon: GetIcons("SystemLogNew"),
      },
    ],
  },
];
const LinksMenu = (label, key, children) => {
  return (
    <Link
      id={Math.random()}
      to={{
        pathname: `${children ? "/" + children : ""}/${key}`,
        state: {},
      }}
    >
      {label}
    </Link>
  );
};
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children: Array.isArray(children) ? children : null,
    label:
      children && Array.isArray(children)
        ? label
        : LinksMenu(label, key, children),
    type,
  };
}
export const convertSidebarLinks = (list, mode) => {
  return list
    ?.sort(function (a, b) {
      return a?.index?.[0]?.[mode] - b?.index?.[0]?.[mode];
    })
    ?.map((d) => {
      if (d?.children && d?.children?.length !== 0) {
        return getItem(
          d?.title,
          d?.name,
          d?.icon,
          d?.children?.map((c) => {
            return getItem(c.title, c.name, c.icon, c.parent);
          })
        );
      }
      return getItem(d.title, d.name, d.icon);
    });
};
export const MyNewSidebarFunc = (userMenus, mode) => {
  if (mode === "admin") {
    return userMenus;
  }
  let menuList = localStorage.getItem("menusList")
    ? JSON.parse(localStorage.getItem("menusList")) || []
    : [];
  let menus = [];
  userMenus.forEach((d) => {
    const index = menuList?.findIndex((a) => a === d.key);
    if (index !== -1) {
      menus.push(d);
    }
  });
  return menus;
};
export const ModulesList = (props) => {
  const { type, mode } = props;
  if (type === "sidebar") {
    return MyNewSidebarFunc(
      convertSidebarLinks(
        Modules?.filter((d) => d?.rule?.findIndex((t) => t === mode) !== -1),
        mode
      ),
      mode
    );
  } else if (type === "url") {
    let obj = {};
    Modules.forEach((r) => {
      r.path.forEach((p) => {
        obj = {
          ...obj,
          [p]: {
            name: r.title,
            parent: r.parentName,
            path: r.parentPath,
          },
        };
      });
      r?.children?.forEach((p) => {
        p.path.forEach((i) => {
          obj = {
            ...obj,
            [i]: {
              name: p?.title,
              path: r?.parentPath + i,
              parent: r?.title,
            },
          };
        });
      });
    });
    return obj;
  }
  return [];
};
