import React, { useEffect, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import AuthWrapper from "../style";
import { useSelector } from "react-redux";
import { Button, Form, Input, message } from "antd";
import { urlDecode } from '../../../../config'
const formItemLayout = {
  labelCol: {
    xs: {
      span: 16,
    },
    sm: {
      span: 16,
    },
  },
};
export default function (props) {
  const { resetPasswordAction, fakeActionAuth } = props;
  const location = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const ResetPasswordRes = useSelector(
    (state) => state.Auth.ResetPasswordResponse || {}
  );

  useEffect(() => {
    if (ResetPasswordRes?.status === true) {
      setLoading(false);
      setRedirect(true);
      message.success(
        ResetPasswordRes?.message || "Your password changed successfully."
      );
      fakeActionAuth("ResetPasswordResponse");
    } else if (ResetPasswordRes?.status === false) {
      setLoading(false);
      message?.destroy();
      if (ResetPasswordRes?.error || ResetPasswordRes?.error_data) {
        message.warning(
          Object.values(
            ResetPasswordRes?.error || ResetPasswordRes?.error_data
          )?.[0]?.[0] ||
            ResetPasswordRes?.message ||
            "Something Went Wrong."
        );
      } else {
        message.warning("Something Went Wrong.");
      }
      fakeActionAuth("ResetPasswordResponse");
    }
  }, [ResetPasswordRes]);

  const onCreate = (values) => {
    const state = urlDecode(location)
    setLoading(true);
    resetPasswordAction({
      password: values?.password,
      email: state?.email,
    });
  };

  // if (redirect || !urlDecode(location)?.email) {
  //   return <Redirect to="/login" />;
  // }
  if (localStorage.getItem("userType")) {
    return (
      <Redirect
        to={
          localStorage.getItem("userType") == 1
            ? `/manage-seller-users`
            : `/dashboard`
        }
      />
    );
  }
  document.title = "Forgot Password";
  return (
    <AuthWrapper style={{ height: "100%", overflow: "auto" }}>
      <div className="row" style={{ height: "100%", marginRight: "0px" }}>
        <div
          id="div1"
          style={{ background: "#eff3fe", overflow: "auto" }}
          className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6  flex-column d-flex"
        >
          <div className="d-flex flex-row-fluid flex-column text-center p-10">
            <span className="cursor-pointer py-9 mb-5 mt-20 d-flex justify-content-center">
              <img
                alt="Logo"
                loading="lazy"
                src="/logos/full-logo.png"
                className="h-70px  shimmer"
                onError={(e) => {
                  e.target.src =
                    "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
                }}
              />
            </span>
            <h1
              className="fs-2qx pb-5 pb-md-4 fw-normal"
              style={{ color: "#494951" }}
            >
              Welcome to <b className="fw-boldest">Amzster</b>
            </h1>
            <p className="fw-normal fs-3" style={{ color: "#494951" }}>
              An Intelligent tool for Amazon Sellers
            </p>
          </div>
          <div className="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px">
            <img
              src="/assets/back-add-user-01.png"
              alt="login-image.png"
              className="mx-auto"
              loading="lazy"
              style={{ width: "50%" }}
              onError={(e) => {
                e.target.src =
                  "http://accordelectrotechnics.in/img/product/no-preview/no-preview.png";
              }}
            />
          </div>
        </div>
        <div
          id="div2"
          style={{ background: "#fff", height: "auto", overflow: "auto" }}
          className="d-flex flex-column col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
        >
          <div className="d-flex flex-column flex-lg-row-fluid py-10">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div className="w-lg-500px  mx-auto">
                <div className="mb-10 text-center">
                  {/*begin::Title*/}
                  <h1
                    className="text-dark fw-bold mb-3"
                    style={{ color: "#494951" }}
                  >
                    Setup New <b className="fw-boldest">Password</b>
                  </h1>
                  {/*end::Title*/}
                  {/*begin::Link*/}
                  <div className="text-gray-400 fw-bold fs-4">
                    Already have reset your password ?
                    <Link to="/login" className="link-primary fw-bolder">
                      Sign in here
                    </Link>
                  </div>
                  {/*end::Link*/}
                </div>
                <Form
                  {...formItemLayout}
                  layout="vertical"
                  form={form}
                  name="reset-password"
                  style={{ width: "100%" }}
                  disabled={loading}
                >
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Password is required",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password
                      autoFocus
                      size="large"
                      autocomplete="new-password"
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Confirm password is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password autocomplete="off" size="large" />
                  </Form.Item>
                </Form>
                <div className="text-center">
                  <Button
                    type="primary"
                    id="kt_new_password_submit"
                    className=" fw-bolder"
                    size="large"
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          onCreate(values);
                        })
                        .catch((info) => {});
                    }}
                  >
                    {loading ? (
                      <span>
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    ) : (
                      <span className="indicator-label">Save</span>
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <div className="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
              {/*begin::Links*/}
              <div className="d-flex flex-center fw-bold fs-6">
                <a href className="text-muted text-hover-primary px-2">
                  About
                </a>
                <a href className="text-muted text-hover-primary px-2">
                  Support
                </a>
                <a href className="text-muted text-hover-primary px-2">
                  Purchase
                </a>
              </div>
              {/*end::Links*/}
            </div>
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
}
