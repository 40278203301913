import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CreateAccount from "../../../modules/pages/auth/create-account";
import {
  signUpAction,
  checkEmailAction,
  fakeActionAuth,
} from "../../../redux/modules/auth/index.action";

const mapStateToProps = (state) => ({
  SignUpResponse: state.Auth.SignUpResponse,
  CreateUserCheckEmailResponse: state.Auth.CreateUserCheckEmailResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signUpAction,
      checkEmailAction,
      fakeActionAuth,
    },
    dispatch
  );

const CreateAccountASINWiser = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateAccount);

export default CreateAccountASINWiser;
