export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "ADMIN_SYSTEM_EVENT_PROCESS_SUCCESS":
    case "ADMIN_SYSTEM_EVENT_PROCESS_ERROR":
      return {
        ...state,
        GetSystemEventProcessResponse: action.updatePayload,
      };
    case "USER_SYSTEM_EVENT_PROCESS_SUCCESS":
    case "USER_SYSTEM_EVENT_PROCESS_ERROR":
      return {
        ...state,
        GetUserSystemEventProcessResponse: action.updatePayload,
      };
    case "GET_MARKETPLACE_LIST_SUCCESS":
    case "GET_MARKETPLACE_LIST_ERROR":
      return {
        ...state,
        GetMarketplaceListResponse: action.updatePayload,
      };
    case "EVENT_PROCESS_TYPE_SUCCESS":
    case "EVENT_PROCESS_TYPE_ERROR":
      return {
        ...state,
        EventProcessTypeListResponse: action.updatePayload,
      };
    case "EVENT_NAME_LIST_SUCCESS":
    case "EVENT_NAME_LIST_ERROR":
      return {
        ...state,
        EventNameListResponse: action.updatePayload,
      };
    case "SYSTEM_EVENT_LOGS_LIST_SUCCESS":
    case "SYSTEM_EVENT_LOGS_LIST_ERROR":
      return {
        ...state,
        SystemEventLogsListResponse: action.updatePayload,
      };
    case "EVENT_TYPE_LIST_SUCCESS":
    case "EVENT_TYPE_LIST_ERROR":
      return {
        ...state,
        EventTypeListResponse: action.updatePayload,
      };
    case "USER_EVENT_NAME_LIST_SUCCESS":
    case "USER_EVENT_NAME_LIST_ERROR":
      return {
        ...state,
        UserEventNameListResponse: action.updatePayload,
      };
    case "SELLER_PERFORMANCE_DATA_SUCCESS":
    case "SELLER_PERFORMANCE_DATA_ERROR":
      return {
        ...state,
        SellerPerformanceDataResponse: action.updatePayload,
      };
    case "GET_USER_CREDENTIALS_SUCCESS":
    case "GET_USER_CREDENTIALS_ERROR":
      return {
        ...state,
        getUserCredentialsResponse: action.updatePayload,
      };
    case "FAKE_ACTION_APP_LOGS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
