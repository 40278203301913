export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "CRITERIA_ANALYSIS_SUCCESS":
    case "CRITERIA_ANALYSIS_ERROR":
      return {
        ...state,
        CriteriaAnalysisResponse: action.updatePayload,
      };
    case "DELETE_CRITERIA_ANALYSIS_SUCCESS":
    case "DELETE_CRITERIA_ANALYSIS_ERROR":
      return {
        ...state,
        DeleteCriteriaAnalysisResponse: action.updatePayload,
      };
    case "EDIT_CRITERIA_ANALYSIS_SUCCESS":
    case "EDIT_CRITERIA_ANALYSIS_ERROR":
      return {
        ...state,
        EditCriteriaAnalysisResponse: action.updatePayload,
      };
    case "ADD_CRITERIA_ANALYSIS_SUCCESS":
    case "ADD_CRITERIA_ANALYSIS_ERROR":
      return {
        ...state,
        AddCriteriaAnalysisResponse: action.updatePayload,
      };
    case "CRITERIA_ANALYSIS_MANUAL_LIST_SUCCESS":
    case "CRITERIA_ANALYSIS_MANUAL_LIST_ERROR":
      return {
        ...state,
        CriteriaAnalysisManualListResponse: action.updatePayload,
      };
    case "ITEM_CRITERIA_FILTER_DATA_SUCCESS":
    case "ITEM_CRITERIA_FILTER_DATA_ERROR":
      return {
        ...state,
        ItemCriteriaFilterDataResponse: action.updatePayload,
      };
    case "ITEM_CRITERIA_UPDATE_SUCCESS":
    case "ITEM_CRITERIA_UPDATE_ERROR":
      return {
        ...state,
        ItemCriteriaUpdateResponse: action.updatePayload,
      };
    case "ITEM_CRITERIA_DELETE_SUCCESS":
    case "ITEM_CRITERIA_DELETE_ERROR":
      return {
        ...state,
        ItemCriteriaDeleteResponse: action.updatePayload,
      };
    case "ITEM_CRITERIA_ADD_SUCCESS":
    case "ITEM_CRITERIA_ADD_ERROR":
      return {
        ...state,
        ItemCriteriaAddResponse: action.updatePayload,
      };
    case "CRITERIA_STATUS_RANGE_SUCCESS":
    case "CRITERIA_STATUS_RANGE_ERROR":
      return {
        ...state,
        CriteriaStatusRangeResponse: action.updatePayload,
      };
    case "CRITERIA_STATUS_RANGE_DELETE_SUCCESS":
    case "CRITERIA_STATUS_RANGE_DELETE_ERROR":
      return {
        ...state,
        CriteriaStatusDeleteRangeResponse: action.updatePayload,
      };
    case "CRITERIA_STATUS_RANGE_UPDATE_SUCCESS":
    case "CRITERIA_STATUS_RANGE_UPDATE_ERROR":
      return {
        ...state,
        CriteriaStatusUpdateRangeResponse: action.updatePayload,
      };
    case "CRITERIA_STATUS_RANGE_ADD_SUCCESS":
    case "CRITERIA_STATUS_RANGE_ADD_ERROR":
      return {
        ...state,
        CriteriaStatusAddRangeResponse: action.updatePayload,
      };
    case "FAKE_ACTION_CRITERIA_ANALYSIS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
