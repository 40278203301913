export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "UPDATE_PROFILE_SETTING_SUCCESS":
    case "UPDATE_PROFILE_SETTING_ERROR":
      return {
        ...state,
        UpdateProfileSettingResponse: action.updatePayload,
      };
    case "PROFILE_CHANGE_PASSWORD_SETTING_SUCCESS":
    case "PROFILE_CHANGE_PASSWORD_SETTING_ERROR":
      return {
        ...state,
        ChangePasswordSettingResponse: action.updatePayload,
      };
    case "FAKE_ACTION_PROFILE":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
