import { ConfigProvider } from "antd";
import { createHashHistory } from "history";
import React, { useState } from "react";
import { Provider } from "react-redux";
import AppRoute from "./AppRouter";
import AppStyleWrapper from "./AppStyle";
import GlobalCommonContextProvider from "./commonContext";
import NoData from "./components/no-data";
import { themeColor } from "./config";
import { store } from "./redux/configurations/store/configure-store";

export default function App() {
  return (
    <ConfigProvider
      renderEmpty={() => <NoData />}
      theme={{
        token: {
          fontFamily: "Inter",
          colorPrimary: themeColor(),
          colorPrimaryHover: themeColor(),
        },
      }}
    >
      <GlobalCommonContextProvider>
        <Provider store={store}>
          <AppStyleWrapper />

          <AppRoute history={createHashHistory()} />
        </Provider>
      </GlobalCommonContextProvider>
    </ConfigProvider>
  );
}
