import React, { Suspense } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "./core/helper/asyncComponent";
import RootLoading from "./components/loading/rootLoading";
import CreateAccount from "./containers/auth/lib";
import MailSentASINWiser from "./containers/auth/lib/mail-sent";
import ResetPasswordASINWiser from "./containers/auth/lib/reset-password";
import UserActiveASINWiser from "./containers/auth/user-active";
const Login_ = React.lazy(() => import("./containers/auth"));

const Pages = React.lazy(() => import("./containers"));

function AppRoute(props) {
  return (
    <BrowserRouter history={props.history}>
      <Suspense fallback={<RootLoading />}>
        <Switch>
          <Route path="/login" component={Login_} exact />
          <Route path="/create-account" component={CreateAccount} exact />
          <Route path="/forgot-password" component={MailSentASINWiser} exact />
          <Route path="/user/activate" component={UserActiveASINWiser} exact />
          <Route
            path="/reset-password"
            component={ResetPasswordASINWiser}
            exact
          />
          <Route path="/" component={Pages} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRoute;
